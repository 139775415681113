<script setup lang="ts">
import { useMainStore } from '~/store/main/main.store'

defineProps({
  tariff: {},
})

defineEmits(['close'])

const mainStore = useMainStore()
const partner = computed(() => mainStore.getDomainConfig)
const segmentation = computed(() => mainStore.getSegmentation)
const oldClientCookie = useCookie('oldClient', { maxAge: 60 * 60 * 24 })
onMounted(() => {
  console.log(mainStore.oldClient)
  mainStore.$patch({
    oldClient: segmentation.value,
  })
  oldClientCookie.value = segmentation.value
})
</script>

<template>
  <div class="p-support white--bg">
    <div class="p-support__wrap relative">
      <button
        class="p-support__close d-flex align-center justify-center"
        @click="$emit('close')"
      >
        <img
          loading="lazy"
          src="@/assets/images/svg/close.svg"
          alt="close"
        >
      </button>
      <template v-if="tariff">
        <p class="p-support__overtitle subhead-3 gray-dark--text">
          Тариф «{{ tariff }}»
        </p>
        <p class="p-support__title headline-1">
          Вы являетесь действующим клиентом МТС
        </p>
        <p class="p-support__subtitle title-5">
          Мы не сможем ответить на вопросы по действующему подключению или сменить ваш текущий тариф.
        </p>
        <p class="p-support__subtitle title-5">
          Несколько способов связаться с поддержкой: в <a
            href="https://mymts.ru/"
            style="color: #007CFF"
            class="black--text"
          >приложении Мой МТС</a> или в <a
            :href="partner?.provider?.lk_url"
            style="color: #007CFF"
            class="black--text"
          >Личном кабинете</a>
        </p>
      </template>
      <template v-else>
        <p class="p-support__title headline-1">
          Поддержка МТС
        </p>
        <p class="p-support__subtitle title-2">
          Несколько способов связаться с поддержкой: в <a
            href="https://mymts.ru/"
            style="color: #007CFF"
            class="black--text"
          >приложении Мой МТС</a> или в <a
            :href="partner?.provider?.lk_url"
            style="color: #007CFF"
            class="black--text"
          >Личном кабинете</a>
        </p>
      </template>
      <div class="p-support__app">
        <p class="p-support__app-title title-4">
          Чат в приложении Мой МТС
        </p>
        <ol class="p-support__app-list">
          <li class="p-support__app-item">
            Наведите камеру на QR-код и установите <a
              href="https://mymts.ru/"
              style="color: #007CFF"
              class="black--text"
            >приложение Мой МТС</a>
            <span class="d-flex">
              <img
                src="@/assets/images/useful/mtc-support.png"
                alt="support"
                class="p-support__app-img"
              >
            </span>
          </li>
          <li class="p-support__app-item">
            В нижнем меню перейдите в раздел «Поддержка»
          </li>
          <li class="p-support__app-item">
            Нажмите кнопку «Написать в чат»
          </li>
          <li class="p-support__app-item">
            Подготовьте скриншоты или документы, подробно опишите проблему, пока ждёте оператора
          </li>
          <li class="p-support__app-item">
            Когда поступит ответ, вам придёт уведомление от приложения
          </li>
        </ol>
      </div>
      <div class="p-support__lk">
        <p class="p-support__lk-title title-4">
          Чат в личном кабинете
        </p>
        <ol class="p-support__lk-list">
          <li class="p-support__lk-item">
            Войдите в <a
              :href="partner?.provider?.lk_url"
              style="color: #007CFF"
              class="black--text"
            >Личный кабинет</a>
          </li>
          <li class="p-support__lk-item">
            В правом нижнем углу откройте виджет чата
          </li>
          <li class="p-support__lk-item">
            Подготовьте скриншоты или документы, подробно опишите проблему, пока ждёте оператора
          </li>
          <li class="p-support__lk-item">
            Дождитесь ответа, не закрывая страницу
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.p-support {
  padding: 40px;
  border-radius: 20px;
  max-width: 700px;
  max-height: 100%;
  @media (max-width: getBreakpoint(desktop)) {
    max-width: 100%;
    height: 100%;
    border-radius: 20px 20px 0 0;
    padding: 32px 40px;
  }
  @media (max-width: getBreakpoint(tablet)) {
    padding: 24px 20px 28px;
  }
  @media (max-width: getBreakpoint(mobile-lg)) {
    padding: 24px 16px 28px;
  }
  &__close {
    position: absolute;
    right: 20px;
    top: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    background: color(gray-p);
    z-index: 1;
    @media (max-width: getBreakpoint(desktop)) {
      top: 4px;
      right: 16px;
    }
    @media (max-width: getBreakpoint(tablet)) {
      width: 32px;
      height: 32px;
    }
    img {
      opacity: .7;
      transition: opacity .24s;
      width: 24px;
      height: 24px;
      @media (max-width: getBreakpoint(tablet)) {
        width: 20px;
        height: 20px;
      }
    }

    &:hover {
      img {
        opacity: 1;
      }
    }
  }
  &__wrap {
    max-height: calc(100% + 40px);
    overflow-y: auto;
    margin-right: -40px;
    padding-right: 40px;
    margin-top: -20px;
    padding-top: 20px;
    margin-bottom: -20px;
    padding-bottom: 20px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-right: -20px;
      padding-right: 20px;
      padding-top: 12px;
      margin-top: -12px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      margin-right: -16px;
      padding-right: 16px;
    }
  }

  &__title, &__overtitle {
    margin-bottom: 8px;
  }
  &__subtitle {
    margin-bottom: 28px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 20px;
    }
  }

  &__app {
    margin-bottom: 28px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 20px;
    }
    &-img {
      margin-top: 12px;
      margin-bottom: 12px;
      width: 100px;
      height: 100px;
    }
  }
  &__app, &__lk {

    &-title {
      margin-bottom: 12px;
    }

    &-list {
      padding-left: 32px;
    }
  }
}
</style>
